/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Modal, ModalFooter } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useLogin, forgetPassword, useLogout } from "../../services/auth";
import API from "../../services/api";
import Disclaimer from "../../components/Disclaimer";
import CustomButton from "../../components/common/CustomButton";

import classes from "./Login.module.css";
import { getCurrentYear } from "../../helpers/common";

function Login(props) {
  const { refetch } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("password");
  const [emailSent, setEmailSent] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [mfaIdentToken, setMfaIdentToken] = useState(null);
  const [OTPSent, setOTPSent] = useState(false);
  const [opt, setOpt] = useState();
  const [countdown, setCountdown] = useState(30);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const [isVerifiedLoading, setIsVerifiedLoading] = useState(false);

  const handleVerificationModal = () => {
    setOpt();
    setShowVerificationModal((prev) => !prev);
  };

  const handleVerify = (e) => {
    e.preventDefault();
    setIsVerifiedLoading(true);
    API.post("auth/mfa/verifytoken", {
      token: opt,
      mfaIdentToken: mfaIdentToken,
    })
      .then((res) => {
        setShowVerificationModal(false);
        setShowDisclaimer(true);
      })
      .catch((error) => {
        const { msg, data } = error.response.data;

        alert(msg);
        setMfaIdentToken(data.auth.mfaIdentToken);
      })
      .finally(() => {
        setIsVerifiedLoading(false);
      });
  };

  const resendOTP = () => {
    const timeout = setTimeout(() => {
      setOTPSent(false);
    }, 30 * 1000);

    setOTPSent(true);

    API.post("/auth/mfa/resendtoken", {
      mfaIdentToken: mfaIdentToken,
    })
      .then((res) => {
        const { msg, data } = res.data;
        alert(msg);
        setMfaIdentToken(data.auth.mfaIdentToken);
      })
      .catch(() => {
        clearTimeout(timeout);
      });
    return () => {
      clearTimeout(timeout);
    };
  };

  const toggleShowPassword = () => {
    if (showPassword === "password") {
      setShowPassword("text");
    } else {
      setShowPassword("password");
    }
  };

  const navigate = useNavigate();

  const { isLoading: isLoginLoading, mutateAsync: loginMutation } = useLogin({
    onSuccess: async (response) => {
      const data = response;

      if (data.isMfaRequired) {
        handleVerificationModal();
        setMfaIdentToken(data.mfaIdentToken);
      } else {
        refetch();
        navigate("/Dashboard");
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleConfirm = () => {
    refetch();
    navigate("/Dashboard");
  };

  const { mutateAsync: logoutMutation } = useLogout({
    onSuccess: (response) => {
      refetch();
    },
  });
  const handleCancel = () => {
    logoutMutation();
    setShowDisclaimer(false);
  };

  const { mutateAsync: sendResetLinkMutation } = forgetPassword({
    onSuccess: (response) => {
      setEmailSent(true);
      setShow(false);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    },
    onError: (error) => {
      alert("User not found, please contact It support at itsupport@caipa.com");
    },
  });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (countdown > 0 && OTPSent) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
      // eslint-disable-next-line no-else-return
    } else if (countdown === 0 && OTPSent) {
      setOTPSent(false);
      setCountdown(30);
    }
  }, [countdown, OTPSent]);

  return (
    <div className="login">
      <div className="login-container">
        <img
          className="caipaLogo"
          alt="loaginPageCaipaLogo"
          src="../image/portal_CAIPA_MSO.png"
          style={{ width: "380px", margin: "47px 0 0 0" }}
        />
        <h3
          style={{
            marginTop: "74px",
            color: "#004990",
            fontSize: "30px",
            fontWeight: "500",
          }}
        >
          Hello!
        </h3>
        <h3
          style={{
            marginTop: "12px",
            color: "#004990",
            fontSize: "24x",
            fontWeight: "500",
          }}
        >
          Login Your Account
        </h3>
        <Form
          className="loginInput"
          style={{ margin: "5% 0" }}
          onSubmit={(e) => {
            e.preventDefault();
            loginMutation({
              username,
              password,
            });
          }}
        >
          {/* EMAIL AND PASSWORD FIELD CAN ALSO BE USED FOR REGISTER NEW USER */}

          <Form.Group className="mb-3">
            <Form.Control
              placeholder="Email Address"
              type="email"
              required
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="">
            <Form.Control
              placeholder="Password"
              type={showPassword}
              required
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Form.Group>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                id="show_password"
                type="checkbox"
                onChange={() => {
                  if (showPassword === "password") {
                    setShowPassword("text");
                  } else {
                    setShowPassword("password");
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    toggleShowPassword();
                  }
                }}
                checked={showPassword === "text"}
                style={{
                  width: "15px",
                  margin: "0 15px 0 0",
                  cursor: "pointer",
                }}
              />
              <label
                className="forgotPasswordLink"
                htmlFor="show_password"
                style={{ cursor: "pointer", color: "#004990" }}
              >
                Show Password
              </label>
            </div>
            <div>
              <button
                type="button"
                className="forgotPasswordLink"
                onClick={handleShow}
                style={{ color: "#004990" }}
              >
                Forget Password?
              </button>
            </div>
          </div>

          <div className="loginButton">
            <button
              type="submit"
              disabled={isLoginLoading}
              style={{
                cursor: isLoginLoading ? "not-allowed" : "pointer",
                borderRadius: "4px",
              }}
            >
              {isLoginLoading ? "Loading..." : "LOG IN"}
            </button>
          </div>
        </Form>
        <div style={{ color: "gray", margin: "2% 0 0 0", fontSize: "12px" }}>
          © {getCurrentYear()} All content on this site is copyrighted by CAIPA,
          Inc.
        </div>
      </div>

      {/* pop up modal */}

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="_g-modal-dialog_"
        backdrop="static"
      >
        <Modal.Header className="border-0" closeButton />
        <Modal.Body style={{ minHeight: "30vh" }}>
          <div id="testchange">
            <p
              className="m-auto"
              style={{ width: "70%", fontSize: "20px", textAlign: "center" }}
            >
              Enter the email associated with your account and well send you a
              link to reset your password
            </p>
            <div className="loginInput mt-5">
              <input
                placeholder="Email Address"
                style={{ height: "40px" }}
                type="email"
                id="resetPassword"
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    sendResetLinkMutation({ email });
                  }
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <div className="loginButton" style={{ marginTop: "0" }}>
                <button
                  type="button"
                  style={{
                    width: "100%",
                    borderRadius: "4px",
                    backgroundColor: "#004990",
                  }}
                  onClick={() => sendResetLinkMutation({ email })}
                >
                  SEND
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <ModalFooter className="_g-modal-footer_">
          <img
            className="caipaLogo"
            alt="loaginPageCaipaLogo"
            src="../image/portal_CAIPA_MSO.png"
          />
        </ModalFooter>
      </Modal>

      <Modal show={emailSent === true} onHide={handleClose} backdrop="static">
        <Modal.Header className="border-0" closeButton />
        <Modal.Body style={{ minHeight: "30vh" }}>
          <div id="testchange" className={classes.email_logo_wrapper}>
            <img
              className="caipaLogo"
              style={{ width: "150px" }}
              alt="emailopen"
              src="../image/Email_Open.png"
            />
            <p
              className="m-auto"
              style={{ width: "100%", fontSize: "20px", textAlign: "center" }}
            >
              Reset password instruction will be sent to you shortly. Redirect
              in 5 second.
            </p>
          </div>
        </Modal.Body>
        <ModalFooter className="_g-modal-footer_">
          <img
            className="caipaLogo"
            alt="loaginPageCaipaLogo"
            src="../image/portal_CAIPA_MSO.png"
          />
        </ModalFooter>
      </Modal>
      <Modal
        show={showVerificationModal}
        onHide={handleVerificationModal}
        className={classes.verification_modal}
        dialogClassName="_g-modal-dialog_"
        backdrop="static"
      >
        <Form onSubmit={handleVerify}>
          <Modal.Header closeButton>
            <span style={{ fontSize: "18px", fontWeight: "600" }}>
              One-time Passcode Verification
            </span>
          </Modal.Header>
          <Modal.Body style={{ minHeight: "auto" }}>
            <div
              style={{
                paddingBottom: "40px",
                color: "black",
                textAlign: "left",
              }}
            >
              <div>
                Please check your email for a 6-digits one-time passcode. Enter
                or paste the code into the box below and submit. Do not refresh
                or close page. Otherwise re-login is required.
              </div>
            </div>

            <div>
              <Form.Control
                required
                className=""
                name="otp"
                value={opt}
                onChange={(e) => {
                  setOpt(e.target.value);
                }}
                type="text"
                minLength={6}
                maxLength={6}
                size={6}
                style={{ textAlign: "center", fontWeight: "bold" }}
              />
            </div>
            <br className="" />
            <div
              style={{
                padding: "0px ",
                // fontWeight: "600",
                color: "black",
                textAlign: "left",
              }}
            >
              Click on &apos;Resend Code&apos; if the one-time passcode is
              expired or didn&apos;t receive an email. A new one-time passcode
              can be resend every 30s.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <CustomButton
              variant="primary"
              type="button"
              disabled={OTPSent}
              onClick={resendOTP}
              style={{ marginRight: "20px", width: "auto" }}
            >
              {OTPSent ? `Resend Code (${countdown})` : "Resend Code"}
            </CustomButton>
            <CustomButton
              variant="primary"
              type="submit"
              disabled={isVerifiedLoading}
              style={{
                width: "auto",
                cursor: isVerifiedLoading ? "not-allowed" : "pointer",
              }}
            >
              {isVerifiedLoading ? "Loading..." : "Verify"}
            </CustomButton>
          </Modal.Footer>
        </Form>
      </Modal>
      <Disclaimer
        showButton
        showDisclaimer={showDisclaimer}
        handleConfirm={handleConfirm}
        refetch={() => refetch()}
        handleCancel={handleCancel}
      />
    </div>
  );
}

export default Login;

Login.propTypes = {
  refetch: PropTypes.func.isRequired,
};
