/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import axios from "axios";
import Cookies from "js-cookie";

const getAuthorizationCode = () => Cookies.get("user_id") ?? "";

const devURL = "http://localhost:3001/api";
const prodURL = "https://bdportal.caipamso.com/api";
const qaURL = "https://bdportal.qa.caipa.com/api";

const devDomain = "http://localhost:3000";
const prodDomain = "https://bdportal.caipamso.com";
const qaDomain = "https://bdportal.qa.caipa.com";

const nodeEnv = process.env.REACT_APP_NODE_ENV;
export let API_URL = prodURL;
export let DOMAIN_URL = prodDomain;
if (nodeEnv === "dev") {
  API_URL = devURL;
  DOMAIN_URL = devDomain;
}
if (nodeEnv === "qa") {
  API_URL = qaURL;
  DOMAIN_URL = qaDomain;
}

const API = axios.create({
  baseURL: API_URL,
  timeout: 60000,
  withCredentials: true,
  headers: {
    // 'Access-Control-Allow-Origin': 'http://13.58.157.219/',
    Authorization: {
      toString() {
        return getAuthorizationCode();
      },
    },
  },
});

export default API;

export const getLastSeen = (fileId, userId, set) => {
  API.get(`/getLastSeen`, {
    params: {
      fileId,
      userId,
    },
  }).then((response) => {
    if (response.data[0] === undefined || response.data[0].last_seen == null) {
      set("");
    } else {
      // set(new Date(response.data[0].last_seen).toLocaleString('en'));
      // const time = response.data[0].last_seen.split('T');
      const time = response.data[0].last_seen.replace("Z", "");
      // set(`${time[0]} , ${new Date(time).toLocaleTimeString()}`);
      set(new Date(time).toLocaleString());
    }
  });
};

export const getAllLastSeen = (fileId, set) => {
  API.get(`/admin/getAllLastSeen`, {
    params: {
      fileId,
    },
  }).then((response) => {
    if (response.data.data === undefined || response.data.data == null) {
      set([]);
    } else {
      set(response.data.data);
    }
  });
};

export const getAllUsers = (set) => {
  API.get(`/getAllUsers`).then((response) => {
    if (response.data.data.users === undefined) {
      set([]);
    } else {
      set(response.data.data.users);
    }
  });
};

export const updateLastSeen = (fileId, userId) => {
  API.put(`/updateLastSeen`, {
    fileId,
    userId,
  }).then((response) => {
    // setItemApprove(0);
    // setRefetch(true);
  });
};

export const sendLog = (userId, action) => {
  API.post(`/logEvent`, {
    user_id: userId,
    event: action,
  });
};
export const sortList = (type, sortDirection, setSortDirection, folderList) => {
  if (type === "folder") {
    if (sortDirection) {
      folderList.sort((a, b) => {
        setSortDirection(false);
        return a.folder_name.localeCompare(b.folder_name);
      });
    } else {
      folderList.sort((a, b) => {
        setSortDirection(true);
        return b.folder_name.localeCompare(a.folder_name);
      });
    }
  } else if (type === "modifided") {
    if (sortDirection) {
      folderList.sort((a, b) => {
        setSortDirection(false);
        if (Date.parse(a.lastModified) > Date.parse(b.lastModified)) {
          return 1;
        }
        return -1;
      });
    } else {
      folderList.sort((a, b) => {
        setSortDirection(true);
        // return b.lastModified.localeCompare(a.lastModified);
        if (Date.parse(b.lastModified) > Date.parse(a.lastModified)) {
          return 1;
        }
        return -1;
      });
    }
  } else if (type === "date") {
    if (sortDirection) {
      folderList.sort((a, b) => {
        setSortDirection(false);
        if (Date.parse(a.created_date) > Date.parse(b.created_date)) {
          return 1;
        }
        return -1;
      });
    } else {
      folderList.sort((a, b) => {
        setSortDirection(true);
        if (Date.parse(b.created_date) > Date.parse(a.created_date)) {
          return 1;
        }
        return -1;
      });
    }
  }
};

export const sortFile = (type, sortDirection, setSortDirection, fileList) => {
  if (type === "folder") {
    if (sortDirection) {
      fileList.sort((a, b) => {
        setSortDirection(false);
        return a.file_name.localeCompare(b.file_name);
      });
    } else {
      fileList.sort((a, b) => {
        setSortDirection(true);
        return b.file_name.localeCompare(a.file_name);
      });
    }
  } else if (type === "last_seen") {
    if (sortDirection) {
      fileList.sort((a, b) => {
        setSortDirection(false);
        if (Date.parse(a.last_seen) > Date.parse(b.last_seen)) {
          return 1;
        }
        return -1;
      });
    } else {
      fileList.sort((a, b) => {
        setSortDirection(true);
        if (Date.parse(b.createDate) > Date.parse(a.createDate)) {
          return 1;
        }
        return -1;
      });
    }
  } else if (type === "created_on") {
    if (sortDirection) {
      fileList.sort((a, b) => {
        setSortDirection(false);
        if (Date.parse(a.created_date) > Date.parse(b.created_date)) {
          return 1;
        }
        return -1;
      });
    } else {
      fileList.sort((a, b) => {
        setSortDirection(true);
        if (Date.parse(b.created_date) > Date.parse(a.created_date)) {
          return 1;
        }
        return -1;
      });
    }
  }
};
